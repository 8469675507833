<template>
  <div class="flex justify-center items-center mt-4 px-6 pt-4 pb-9">
    <collapse-card
      :model-value="true"
      disable-collapse
      :show-collapse-button="false"
      class="max-w-7xl"
    >
      <template #title>
        <span class="mr-2 text-sm font-medium">
          Product Line Report
        </span>
      </template>
      <template #cardContent>
        <div class="px-5 py-6">
          <form>
            <div class="grid grid-cols-2 gap-x-6 gap-y-5 mb-6">
              <base-auto-complete-input
                v-model="productLineReportFormData.licenseeName"
                :input-props="{
                  type: 'text',
                  containerClass: 'h-full w-full',
                }"
                class="w-full flex items-center"
                label-class="label-w-46"
                label="Licensee"
                :options="getDistinctArray(licenseeNameOptions)"
                :disable-suggestions="productLineReportFormData.licenseeName.length < 2"
              />
              <base-input
                v-model="productLineReportFormData.contractNumber"
                type="text"
                container-class="h-7 flex items-center"
                placeholder="Enter Text..."
                class="px-2 py-1.5 border border-custom-gray-8 text-custom-gray-7 rounded-sm"
              >
                <template #label>
                  <label class="block text-sm text-black mr-3.5 w-46 flex-shrink-0">Contract Number</label>
                </template>
              </base-input>
              <!-- <base-select
                v-model="productLineReportFormData.contractNumber"
                class="w-full flex justify-between items-center text-sm text-left"
                dropdown-btn-container-class="relative w-full max-w-xs"
                placeholder="-- SELECT --"
                label="Contract Number"
                label-class="label-w-46"
                :options="contractNumberOptions"
                show-default-option
                :disabled="!productLineReportFormData.licenseeName"
                @dropdownOpened="fetchLicenseeContractNumbers()"
              /> -->
              <base-select
                v-model="productLineReportFormData.category"
                class="flex items-center text-sm text-left"
                placeholder="-- SELECT --"
                label="Category"
                label-class="label-w-46"
                :options="getDistinctArray(categoryOptions)"
                show-default-option
                @dropdownOpened="fetchCategories()"
              />
              <!-- :disabled="!productLineReportFormData.contractNumber" -->
              <base-select
                v-model="productLineReportFormData.styleGuide"
                class="flex items-center text-sm text-left"
                placeholder="-- SELECT --"
                label="Style Guide"
                label-class="label-w-46"
                :options="getDistinctArray(styleGuideOptions)"
                show-default-option
                @dropdownOpened="fetchStyleGuides()"
              />
              <!-- :disabled="!productLineReportFormData.contractNumber" -->
              <base-select
                v-model="productLineReportFormData.currentStage"
                class="flex items-center text-sm text-left"
                placeholder="-- SELECT --"
                label="Current Stage"
                label-class="label-w-46"
                :options="getDistinctArray(stageOptions)"
                show-default-option
                @dropdownOpened="fetchStageOptions()"
              />
              <base-select
                v-model="productLineReportFormData.nextStage"
                class="flex items-center text-sm text-left"
                placeholder="-- SELECT --"
                label="Next Stage"
                label-class="label-w-46"
                :options="getDistinctArray(stageOptions)"
                show-default-option
                @dropdownOpened="fetchStageOptions()"
              />
              <base-select
                v-model="productLineReportFormData.status"
                class="flex items-center text-sm text-left"
                placeholder="-- SELECT --"
                label="Status"
                label-class="label-w-46"
                :options="getDistinctArray(statusOptions)"
                show-default-option
                @dropdownOpened="fetchStatusOptions()"
              />
              <base-select
                v-model="productLineReportFormData.currentStep"
                class="flex items-center text-sm text-left"
                placeholder="-- SELECT --"
                label="Current Step"
                label-class="label-w-46"
                :options="getDistinctArray(stepOptions)"
                show-default-option
                @dropdownOpened="fetchStepOptions()"
              />
              <div class="flex items-center">
                <span class="block text-sm text-black mr-3.5 w-46 flex-shrink-0">Licensee Notification Date</span>
                <base-date-picker
                  v-model="productLineReportFormData.dateFrom"
                  mode="date"
                  container-class="flex items-center text-sm"
                  label-class="mr-2"
                  :is-today-visible="false"
                  label="From"
                />
              </div>
              <base-date-picker
                v-model="productLineReportFormData.dateTo"
                mode="date"
                container-class="flex items-center text-sm w-60"
                label-class="mr-2"
                :is-today-visible="false"
                label="To"
              />
            </div>
            <div class="flex justify-center items-center">
              <div
                v-if="formLoading"
                class="w-full py-4 text-center"
              >
                <base-svg
                  class="h-4 w-4 mr-1 text-primary-red inline-block"
                  src="icons/circleSpinner.svg"
                  tag="span"
                />
                Loading ...
              </div>
              <div v-else>
                <base-button
                  class="mr-3"
                  variant="btn-primary"
                  :disabled="!isFormValid"
                  text="Generate Report"
                  @click.prevent="handleFormSubmit()"
                />
                <base-button
                  variant="btn-link"
                  text="Reset"
                  @click="handleFormReset()"
                />
              </div>
            </div>
          </form>
        </div>
      </template>
    </collapse-card>
    <!-- confirm product line report modal -->
    <confirm-product-line-report-modal
      v-model="showConfirmReportModal"
      :record-count="reportData?.length||0"
      @accepted="setConfirmReportModalVisibility(false); generateReport();"
      @rejected="setConfirmReportModalVisibility(false)"
    />
  </div>
  <div
    v-if="reportData"
    id="reportContent"
    class="hidden"
  >
    <div class="flex flex-col items-center border border-custom-gray-3 p-4">
      <!-- Header Image -->
      <div class="w-max-pdf">
        <img
          class="h-auto mx-auto"
          :src="require('@/assets/images/asgardHeader.jpg')"
        >
      </div>

      <div class="w-max-pdf text-center mt-2 mx-auto font-light text-custom-gray-3 text-2xl">Marvel Product Line Report</div>

      <!-- Report Details -->
      <div class="mt-2 pl-1 mx-auto flex flex-row w-max-pdf text-xs">
        <div class="w-1/4">
          Marvel Entertainment, LLC <br>
          1290 Avenue of the Americas, 2nd Floor <br>
          New York, NY 10104 <br>
          Attn: Brand Assurance <br>
          212-576-4000 <br>
          Licensing-Questions@marvel.com
        </div>
        <div class="px-8 w-3/4">
          <div v-if="productLineReportFormData.licenseeName">
            Licensee :  {{ productLineReportFormData.licenseeName }}
          </div>
          <div v-if="productLineReportFormData.contractNumber">
            Contract Number :  {{ productLineReportFormData.contractNumber }}
          </div>
          <div v-if="productLineReportFormData.category">
            Category :  {{ productLineReportFormData.category }}
          </div>
          <div v-if="productLineReportFormData.styleGuide">
            Style Guide :  {{ productLineReportFormData.styleGuide }}
          </div>

          <div v-if="productLineReportFormData.currentStage">
            Current Stage :  {{ productLineReportFormData.currentStage }}
          </div>
          <div v-if="productLineReportFormData.nextStage">
            Next Stage :  {{ productLineReportFormData.nextStage }}
          </div>
          <div v-if="productLineReportFormData.status">
            Status :  {{ productLineReportFormData.status }}
          </div>
          <div v-if="productLineReportFormData.currentStep">
            Current Step :  {{ productLineReportFormData.currentStep }}
          </div>

          <div v-if="productLineReportFormData.dateFrom || productLineReportFormData.dateTo">
            Licensee Notified between
            {{ productLineReportFormData.dateFrom ? moment(productLineReportFormData.dateFrom).format('MM/DD/YYYY'):' - ' }}
            and
            {{ productLineReportFormData.dateTo ? moment(productLineReportFormData.dateTo).format('MM/DD/YYYY'):' - ' }}
          </div>
        </div>
      </div>

      <div class="w-max-pdf font-bold mt-8 pl-1 text-xs">{{ reportData.length }} Records</div>

      <table class="w-max-pdf mt-2 pl-1 text-xs border-l border-b">
        <tr class="w-max-pdf flex flex-row">
          <td class="border-r border-t p-1 pb-2 flex-grow-0 font-semibold w-tbl-cell-small text-center">Thumbnail</td>
          <td class="border-r border-t p-1 pb-2 flex-grow-0 font-semibold w-tbl-cell-small text-center">Approval Number</td>
          <td class="border-r border-t p-1 pb-2 flex-grow-0 font-semibold w-tbl-cell-large text-center">Submission Title</td>
          <td class="border-r border-t p-1 pb-2 flex-grow-0 font-semibold w-tbl-cell-small text-center">SKU</td>
          <td class="border-r border-t p-1 pb-2 flex-grow-0 font-semibold w-tbl-cell-small text-center">Article</td>
          <td class="border-r border-t p-1 pb-2 flex-grow-0 font-semibold w-tbl-cell-small text-center">Licensee</td>
          <td class="border-r border-t p-1 pb-2 flex-grow-0 font-semibold w-tbl-cell-small text-center">Style Guide</td>
          <td class="border-r border-t p-1 pb-2 flex-grow-0 font-semibold w-tbl-cell-small text-center">Contract Number</td>
          <td class="border-r border-t p-1 pb-2 flex-grow-0 font-semibold w-tbl-cell-small text-center">Current Stage</td>
          <td class="border-r border-t p-1 pb-2 flex-grow-0 font-semibold w-tbl-cell-small text-center">Marvel Response</td>
          <td class="border-r border-t p-1 pb-2 flex-grow-0 font-semibold w-tbl-cell-small text-center">Marvel Response Date</td>
        </tr>

        <tr v-for="(record,r) in reportData" class="w-max-pdf flex flex-row">
          <td class="border-r border-t p-1 pb-2 break-words flex-grow-0 w-tbl-cell-small text-center">
            <img class="w-12 h-auto" :src="record.thumbnail">
          </td>
          <td class="border-r border-t p-1 pb-2 break-words flex-grow-0 w-tbl-cell-small text-center">
            {{ record.approvalNumber !== 'null' ? record.approvalNumber : '' }}
          </td>
          <td class="border-r border-t p-1 pb-2 break-words flex-grow-0 w-tbl-cell-large text-center">
            {{ record.submissionTitle !== 'null' ? record.submissionTitle : '' }}
          </td>
          <td class="border-r border-t p-1 pb-2 break-words flex-grow-0 w-tbl-cell-small text-center">
            {{ record.sku !== 'null' ? record.sku : '' }}
          </td>
          <td class="border-r border-t p-1 pb-2 break-words flex-grow-0 w-tbl-cell-small text-center">
            {{ record.article !== 'null' ? record.article : '' }}
          </td>
          <td class="border-r border-t p-1 pb-2 break-words flex-grow-0 w-tbl-cell-small text-center">
            {{ record.licensee !== 'null' ? record.licensee : '' }}
          </td>
          <td class="border-r border-t p-1 pb-2 break-words flex-grow-0 w-tbl-cell-small text-center">
            {{ record.styleGuide !== 'null' ? record.styleGuide : '' }}
          </td>
          <td class="border-r border-t p-1 pb-2 break-words flex-grow-0 w-tbl-cell-small text-center">
            {{ record.contractNumber !== 'null' ? record.contractNumber : '' }}
          </td>
          <td class="border-r border-t p-1 pb-2 break-words flex-grow-0 w-tbl-cell-small text-center">
            {{ record.currentStage !== 'null' ? record.currentStage : '' }}
          </td>
          <td class="border-r border-t p-1 pb-2 break-words flex-grow-0 w-tbl-cell-small text-center">
            {{ record.marvelResponse !== 'null' ? record.marvelResponse : '' }}
          </td>
          <td class="border-r border-t p-1 pb-2 break-words flex-grow-0 w-tbl-cell-small text-center">
            {{ record.marvelResponseDate !== 'null' ? moment(record.marvelResponseDate).format('MM/DD/YYYY') : '' }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { getDistinctArray } from '@/helpers/util';
import { ENTER_CONTRACT_NUMBER } from '@/constants/validationMessages';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, WARNING } from '@/constants/alerts';

// import Vue3Html2pdf from 'vue3-html2pdf'
import moment from 'moment';

export default {
    name: 'BAProductLineReport',

    methods: {
        generateReport () {
            this.$refs.html2Pdf.generatePdf();
        }
    },

    // eslint-disable-next-line vue/order-in-components
    components: {
        // Vue3Html2pdf,
        BaseDatePicker: defineAsyncComponent(() => import('@/components/generic-components/BaseDatePicker.vue')),
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseAutoCompleteInput: defineAsyncComponent(() => import('@/components/generic-components/BaseAutoCompleteInput.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        ConfirmProductLineReportModal: defineAsyncComponent(() => import('@/components/ConfirmProductLineReportModal.vue'))
    },

    // eslint-disable-next-line vue/order-in-components
    setup () {
        const store = useStore();
        const { notificationsStack } = useToastNotifications();

        onMounted(() => {
            store.dispatch('baSubmissions/resetAll');
            fetchStyleGuides();
            fetchCategories();
        });

        // product line report form logic
        const productLineReportFormData = reactive({
            category: '',
            styleGuide: '',
            licenseeName: '',
            contractNumber: '',
            currentStage: '',
            nextStage: '',
            status: '',
            currentStep: '',
            dateFrom: '',
            dateTo: ''
        });
        const isFormValid = computed(() => {
            const { licenseeNotificationDate, ...restFormData } = productLineReportFormData;
            return productLineReportFormData.dateFrom || productLineReportFormData.dateTo || Object.values(productLineReportFormData).some(el => el);
        });
        const formLoading = ref(false);

        const reportData = computed(() => {
            const tmp = store.getters['baProductLineReport/getProductLineReport'];
            return tmp;
        });

        const reportClasses = '.w-tbl-cell-small{ width: 8.3% } .w-tbl-cell-large{ width: 16% } .w-max-pdf { max-width: 1000px; width: 1000px; }'

        const handleFormSubmit = async () => {
            if (!isFormValid.value) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'The parameters are not valid'
                });
                return;
            }
            try {
                if (formLoading.value) return;
                formLoading.value = true;
                let report = await store.dispatch('baProductLineReport/productLineReport', {
                    bodyPayload: {
                        // licenseeName: productLineReportFormData.licenseeName,
                        licenseeId: licenseeId.value,
                        contractNumber: productLineReportFormData.contractNumber,
                        category: productLineReportFormData.category,
                        styleGuideName: productLineReportFormData.styleGuide,
                        currentStageName: productLineReportFormData.currentStage,
                        nextStageName: productLineReportFormData.nextStage,
                        statusName: productLineReportFormData.status,
                        currentStepName: productLineReportFormData.currentStep,
                        licenseeNotificationDate:
                          `${productLineReportFormData.dateFrom ? moment(productLineReportFormData.dateFrom).format('MM/DD/YYYY'):''}`
                          +','+
                          `${productLineReportFormData.dateTo ? moment(productLineReportFormData.dateTo).format('MM/DD/YYYY'):''}`,
                    }
                });
                console.log(`report   ${report}`);
                if (report && report === 'Exceeded Limit') {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: 'Record count is larger than allowed size. Please try changing your search criteria.'
                    });

                } else if (report && report.length > 0) {
                    // setConfirmReportModalVisibility(true);

                    let __reportContent = document.getElementById('reportContent').innerHTML;
                    let __reportWindow = window.open('', '_blank', 'width=1050,height=800');
                    if (!__reportWindow) throw new Error('A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.')

                    __reportWindow.document.write(
                        '<html><head><title>Product Line Report</title>' +
                            '<link rel="stylesheet" href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css">' +
                      `<style>${reportClasses}</style>` +
                      '</head><body>'
                    );
                    __reportWindow.document.write(__reportContent);
                    __reportWindow.document.write('</body></html>');
                } else {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'Report records are not available'
                    });
                }
            } catch (err) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: `ERROR..  ${err}`
                });
            } finally {
                await store.dispatch('baProductLineReport/resetProductLineReport')
                formLoading.value = false;
            }
        };

        const handleFormReset = () => {
            productLineReportFormData.category = '';
            productLineReportFormData.styleGuide = '';
            productLineReportFormData.licenseeName = '';
            productLineReportFormData.contractNumber = '';
            productLineReportFormData.currentStage = '';
            productLineReportFormData.nextStage = '';
            productLineReportFormData.status = '';
            productLineReportFormData.currentStep = '';
            productLineReportFormData.dateFrom = '';
            productLineReportFormData.dateTo = '';
        };

        // confirm report modal logic
        const showConfirmReportModal = ref(false);
        const setConfirmReportModalVisibility = (visibility) => {
            showConfirmReportModal.value = visibility;
        };

        // category logic
        const articles = computed(() => store.getters['baLicensee/getArticleContractList']);
        const categoryOptions = computed(() => articles.value && articles.value.data && articles.value.data.length ? articles.value.data.map(art => art.articleCategory) : []);
        const categoryOptionsLoading = ref(false);
        const fetchCategories = async () => {
            try {
                // if (categoryOptionsLoading.value || categoryOptions.value.length) {
                //     return;
                // }
                categoryOptionsLoading.value = true;
                await store.dispatch('baLicensee/fetchDrmArticlesDetails', {
                    params: {}
                });
            } catch (err) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: `Cannot fetch categories for contract: ${productLineReportFormData.contractNumber}`
                });
                // console.error(err);
            } finally {
                categoryOptionsLoading.value = false;
            }
        };

        // style guide logic
        const styleGuideOptions = computed(() => {
            const styleGuides = store.getters['baStyleGuides/getCompleteStyleGuidesList']?.data;
            return styleGuides?.length ? styleGuides.map(el => el.styleGuide) : [];
        });
        const styleGuideOptionsLoading = ref(false);
        const fetchStyleGuides = async () => {
            try {
                if (styleGuideOptionsLoading.value || styleGuideOptions.value.length) {
                    return;
                }
                styleGuideOptionsLoading.value = true;
                await store.dispatch('baStyleGuides/fetchAllStyleGuidesList', {
                    params: {}
                });
            } catch (err) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: `Cannot fetch style guides for contract: ${productLineReportFormData.contractNumber}`
                });
                // console.error(err);
            } finally {
                styleGuideOptionsLoading.value = false;
            }
        };

        // licensee name logic
        const licenseeNameOptions = computed(() => {
            const licensees = store.getters['baLicensee/getLicensees'];
            return licensees && licensees.length ? licensees.map(name => name.licensee) : [];
        });
        const licenseeNameOptionsLoading = ref(false);
        const fetchLicenseeNameOptions = async () => {
            try {
                if (licenseeNameOptionsLoading.value) {
                    return;
                }
                licenseeNameOptionsLoading.value = true;
                await store.dispatch('baLicensee/fetchDrmLicenseesList', {
                    params: {
                        name: productLineReportFormData.licenseeName
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                licenseeNameOptionsLoading.value = false;
            }
        };
        watch(
            () => productLineReportFormData.licenseeName,
            () => {
                if (productLineReportFormData.licenseeName.length >= 2) {
                    fetchLicenseeNameOptions();
                }
            }
        );

        // submission stage logic
        const stageOptions = computed(() => {
            const submissionStages = store.getters['baSubmissions/getSubmissionStages'];
            return submissionStages && submissionStages.length ? submissionStages.map(stage => stage.stageName) : [];
        });
        const stageOptionsLoading = ref(false);
        const fetchStageOptions = async () => {
            try {
                if (stageOptionsLoading.value || stageOptions.value.length) {
                    return;
                }
                stageOptionsLoading.value = true;
                await store.dispatch('baSubmissions/fetchSubmissionStages', {
                    params: {}
                });
            } catch (err) {
                console.error(err);
            } finally {
                stageOptionsLoading.value = false;
            }
        };

        // submission status logic
        const statusOptions = computed(() => {
            const submissionStatus = store.getters['baSubmissions/getSubmissionStatusList'];
            return submissionStatus && submissionStatus.length ? submissionStatus.map(status => status.statusName) : [];
        });
        const statusOptionsLoading = ref(false);
        const fetchStatusOptions = async () => {
            try {
                if (statusOptionsLoading.value || statusOptions.value.length) {
                    return;
                }
                statusOptionsLoading.value = true;
                await store.dispatch('baSubmissions/fetchSubmissionStatusList', {
                    params: {}
                });
            } catch (err) {
                console.error(err);
            } finally {
                statusOptionsLoading.value = false;
            }
        };

        // contract number logic
        const licenseeId = computed(() => {
            const licensees = store.getters['baLicensee/getLicensees'];
            return (licensees?.find(el => productLineReportFormData && productLineReportFormData.licenseeName === el.licensee) || { licenseeId: '' }).licenseeId;
        });

        const contractNumberOptions = computed(() => {
            const contractList = store.getters['baLicensee/getDrmLicenseeContractNumber'];
            return contractList ? contractList.map(id => id.contractNumber) : [];
        });

        const licenseeContractNumberOptionsLoading = ref(false);
        const fetchLicenseeContractNumbers = async () => {
            try {
                if (licenseeContractNumberOptionsLoading.value) {
                    return;
                }
                licenseeContractNumberOptionsLoading.value = true;
                await store.dispatch('baLicensee/fetchDrmLicenseesContractList', {
                    id: licenseeId.value,
                    params: {
                        contractStatus: 'EXECUTED BOOKED'
                    }
                });
                if (contractNumberOptions.value.length === 0) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: 'Unable to fetch Contract Details'
                    });
                }
            } catch (err) {
                console.error(err);
            } finally {
                licenseeContractNumberOptionsLoading.value = false;
            }
        };

        // submission step logic
        const stepOptions = computed(() => {
            const tmpSteps = store.getters['baSubmissions/getSubmissionSteps'];
            return tmpSteps && tmpSteps.length ? tmpSteps.map(step => step.stepName) : [];
        });
        const stepOptionsLoading = ref(false);
        const fetchStepOptions = async () => {
            try {
                if (stepOptionsLoading.value || stepOptions.value.length) {
                    return;
                }
                stepOptionsLoading.value = true;
                await store.dispatch('baSubmissions/fetchSubmissionSteps', {
                    params: {}
                });
            } catch (err) {
                console.error(err);
            } finally {
                stepOptionsLoading.value = false;
            }
        };

        return {
            // product line report form
            productLineReportFormData,
            getDistinctArray,
            isFormValid,
            handleFormSubmit,
            handleFormReset,
            reportData,
            // confirm report modal
            showConfirmReportModal,
            setConfirmReportModalVisibility,
            // category
            categoryOptions,
            ENTER_CONTRACT_NUMBER,
            fetchCategories,
            // style guide
            styleGuideOptions,
            fetchStyleGuides,
            // licensee name
            licenseeNameOptions,
            fetchLicenseeNameOptions,
            // submission stage
            stageOptions,
            fetchStageOptions,
            // submission status
            statusOptions,
            fetchStatusOptions,
            // submission step
            stepOptions,
            fetchStepOptions,
            formLoading,
            moment,
            contractNumberOptions,
            fetchLicenseeContractNumbers
        };
    }
};
</script>

<style scoped>

.w-tbl-cell-small{
  width: 8.3%
}

.w-tbl-cell-large{
  width: 16%
}

.w-max-pdf {
  max-width: 1000px;
  width: 1000px;
}

</style>
